import {UserSexCode} from '../dashboard/symbols/general.symbols';
import {User} from '../auth/symbols';

/**
 * Hex color in CSS format, like '#ffffff'.
 */
export type HexColor = string;


/**
 * Date string in ISO 8601 format, like '2024-04-15T00:40:42.807240-07:00'.
 */
export type ISODate = string;

export interface Person {
  id?: number;
  name?: string;
  display_name?: string;
  date_of_birth?: string;
  sex?: UserSexCode;
  additional_details?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  state?: string;
  country?: string;
  own?: boolean;
  age_group?: number;
  years?: number;
  weight?: number;
  height?: number;
  display_unit?: number;
  user?: User['uid'];
}

export interface Label {
  id: number;
  name: string;
}

export interface BrainSection {
  id: number;
  name: string;
  bars?: number[][];
  mean?: number;
  label_set?: Label[];
  order?: number;
}

export interface LabelVolume {
  label_id: number;
  label_name: string;
  volume: number;
  brainSection?: number;
  category?: string;
}

export interface SubmissionBrainSection {
  brain_section?: BrainSection|number;
  name: string;
  volume: number;
  sub_volumes?: LabelVolume[];
  variations?: any;
  variation_dict?: {[key: string]: number};
  tag?: string;
  percent?: number; // user's volume comparison percentage using +- 3 std
  // display_percent?: number; // user's volume percentile
  bounds: {[key: string]: [number, number]};
}

export interface MobileScannerWaitListEntry {
  email: string;
  country: string;
  postal_code: string;
}


/**
 * Progress statuses for any async operations, like loading, calculations, etc.
 * May be used to display load state without creating a lot of variables.
 */
export enum PROGRESS_STATUSES {
  /** Display that process were not started yet. */
  NOT_INITIALIZED = 'not_initialized',

  /** Display that process started, but not finished yet. */
  IN_PROGRESS = 'in_progress',

  /** Display that process started, and finished successfully. */
  SUCCEED = 'finished',

  /** Display that process started, but were interrupted by unexpected error. */
  INTERRUPTED = 'interrupted',
}


/**
 * Describes all possible error formats.
 * Application can't be sure what fields are present, so such an error object should be defined or unified.
 */
export interface UnknownError extends Error {

  /** If error occurred on backend, it returned as TS error wrap with field `error`. */
  error?: ErrorFromBackend;
}


/** Default backend error. May contain `detail`, `detail_code` or other field related data. */
export interface ErrorFromBackend<T = unknown> {

  /**
   * May be present if error related to scan upload.
   * This error indicates that such submission series were uploaded in the past.
   * This field will contain id of submission with this series.
   */
  id?: string;

  /** Usually contain user message with error descriptions. */
  detail?: string;

  /** Technical information. May be used for error classification in the future. */
  detail_code?: string;

  /** Extend provided type for custom backend error messages. */
  error?: T;
}

/** Clearly defines that related variable contains unique id string. */
export type UID = string;


/**
 * The interface for the error response from the backend.
 * Used for handling errors in the `DashboardChartDataService`.
 * Server returns 200 with this structure in case of an error instead of 4XX or 5XX status code, to avoid console errors.
 */
export interface BackendHandledError<T> {
  /** Contains the data requested if there is no error. */
  data: T;

  /** Contains the error message. */
  errors: string[];
}
